import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './footer.scss';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const footerRef = useRef(null);

    useEffect(() => {
        const animateText = (ref, speed) => {
            const links = ref.current.querySelectorAll('.links-text a');

            links.forEach(link => {
                const chars = link.innerText.split('');
                link.innerHTML = '';

                chars.forEach(char => {
                    const charSpan = document.createElement('span');
                    charSpan.innerText = char;
                    link.appendChild(charSpan);
                });

                gsap.from(link.children, {
                    y: 100,
                    opacity: 0.2,
                    stagger: 0.03,
                    duration: speed,
                    scrollTrigger: {
                        trigger: footerRef.current,
                        start: 'top 80%',
                        end: 'bottom 20%',
                        toggleActions: 'play none none reverse',
                    }
                });

                gsap.to(link.children, {
                    color: '#fff',
                    stagger: 0.03,
                    duration: speed,
                    scrollTrigger: {
                        trigger: footerRef.current,
                        start: 'top 80%',
                        end: 'bottom 20%',
                        toggleActions: 'play none none reverse',
                    }
                });
            });
        };

        animateText(footerRef, 0.1);
    }, []);

    return (
        <div className="wrapper-footer">
            <section className="main-footer" ref={footerRef}>
                <div className="custom-container">
                    <div className="parent">
                        <div className="left-side">
                            <img src="\footer-logo.svg" alt="img" className='img-fluid' />
                            <p>DOP (Data Ownership Protocol) is an innovative approach to data ownership that sets a new standard in blockchain technology.</p>
                            <div className="social-icons">
                                <a href="https://twitter.com/dop_org" target='_blank'><img src="\assets\social-icons\twitter.svg" alt="img" className='img-fluid' /></a>
                                <a href="https://medium.com/dop-org" target='_blank'><img src="\assets\medium.svg" alt="img" className='img-fluid' /></a>
                                <a href="https://www.youtube.com/@DataOwnershipProtocol" target='_blank'><img src="\assets\social-icons\youtube.svg" alt="img" className='img-fluid' /></a>
                                <a href="https://www.linkedin.com/company/dop-org/about/" target='_blank'><img src="\assets\social-icons\linkedin-new.svg" alt="img" className='img-fluid' /></a>
                                <a href="https://www.instagram.com/data_ownership_protocol/" target='_blank'><img src="\assets\community\social-icons\insta.svg" alt="img" className='img-fluid' style={{width: "20px", height: "20px"}} /></a>
                            </div>
                            <p className='right-text'>© {currentYear} DOP, All Rights Reserved</p>
                        </div>
                        <div className="right-side">
                            <div className="right-links">
                                <div className="links-text">
                                    <h6>About the protocol</h6>
                                    <Link class="dropdown-item" to="/company">The Company</Link>
                                    <Link class="dropdown-item" to="/plan">The plan</Link>
                                    <Link class="dropdown-item" to="/tech">The Tech</Link>
                                    {/* <a class="dropdown-item" href="#">The Team</a> */}
                                    <Link class="dropdown-item" to="/vision">The vision</Link>
                                </div>
                                <div className="links-text">
                                    <h6>Community</h6>
                                    <Link to="/community">Community</Link>
                                    <Link to="/ambassador">Ambassador Program </Link>
                                    <Link to="/dopgrant">DOP Grant Program </Link>
                                    <Link to="/oracles">DOP Oracles </Link>
                                </div>
                                <div className="links-text">
                                    <h6>Knowledge hub</h6>
                                    <Link class="dropdown-item" to="/hub">Knowledge hub</Link>
                                    <Link class="dropdown-item" to="/blog">Blog</Link>
                                    <Link class="dropdown-item" to="/dopcreator">DOP for creators</Link>
                                </div>
                                <div className="links-text">
                                    <h6>Contact & Support</h6>
                                    <Link class="dropdown-item" to="/contact">Contact & Support</Link>
                                </div>
                            </div>
                            <div className="tppara">
                        <Link className="innerpara" to="/terms">Terms of Use</Link>
                        <p className="innerpara">|</p>
                        <Link className="innerpara" to="/privacy">Privacy Policy</Link>
                    </div>
                        </div>
                    </div>
                    <p className='right-reserved-text d-none'>© {currentYear} DOP, All Rights Reserved</p>
             
                </div>
            </section>
        </div>
    );
};

export default Footer;
